import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/Layout";
import Post from "../components/Post";

const Home = ({data}) => (
<Layout>
    <h1>On the Turtle's Back</h1>
    <p>My journey through math, science, and programming</p>
    {
        data.allMarkdownRemark.edges.sort((post1, post2) => { 
            var dateA = new Date(post1.node.frontmatter.date)
            var dateB = new Date(post2.node.frontmatter.date)
            return dateB-dateA
        }).map(post => {
            const { title, author, date, description, path, thumbnail } = post.node.frontmatter;

            return (
                <Post
                title={title}
                author={author}
                date={date}
                description={description}
                key={`${date}__${title}`}
                path={path}
                thumbnail={thumbnail}
                />
            )
        })
    }
</Layout>
);

export default Home;

export const AllBlogsQuery = graphql`
query MyQuery {
  allMarkdownRemark {
    edges {
      node {
        frontmatter {
          date
          title
          description
          author
          path
          thumbnail {
            childImageSharp {
              fixed(width: 200, height: 200) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      }
    }
  }
}
`
