import React from "react";
import { Link } from "gatsby";
import "./post.css";
import Img from 'gatsby-image';

const Post = ({ title, author, date, description, path, thumbnail}) => (
<div className="post">
    <div className="post-text">
      <h3 className="post-title">{ title }</h3>
      <p className="post-description">{ description }</p>
      <p className="post-written-by">
          { date }
      </p>
      <Link to={ path }>Read more</Link>
    </div>
    <Img fixed={thumbnail.childImageSharp.fixed}
        className="post-thumbnail"
    />
</div>
);

export default Post;
